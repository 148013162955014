/* global BigInt */

import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { LuCrown } from "react-icons/lu";

const TableWhitelistLeaderboard = () => {
  const [rowData, setRowData] = useState([]);

  // Environment variables
  const tokenIdentifier = process.env.REACT_APP_TOKEN_IDENTIFIER || "EGLD";
  const walletAddress = process.env.REACT_APP_WALLET_ADDRESS;
  const resultsSize = 5000; // Number of transactions to fetch

  // Score and bonus control variables
  const initialTimeBonus = 10000; // Starting time bonus
  const timeBonusDecrement = 20; // Decrement for time bonus per record
  const totalValueMultiplier = 500; // Multiplier for total value bonus

  // enables pagination in the grid
  const pagination = true;

  // sets 10 rows per page (default is 100)
  const paginationPageSize = 100;

  // allows the user to select the page size from a predefined list of page sizes
  const paginationPageSizeSelector = [100];

  // start date and end date
  const startDate = new Date("2024-01-01T00:00:00Z");
  const endDate = new Date("2025-02-01T00:00:00Z");

  const columnDefs = [
    {
      field: "rank",
      headerName: "Rank",
      maxWidth: 88,
      minWidth: 88,
      sort: "asc",
      resizable: false,
      cellRenderer: (params) => {
        // Check if the rank is 1 and render a crown icon or the rank number
        if (params.value === 1) {
          return (
            <span>
              <LuCrown className="my-3 text-yellow-400 text-lg" />
            </span>
          );
          // If you're using FontAwesome or similar, you might return something like:
          // return <i className="fas fa-crown"></i>;
        } else {
          return <span>{params.value}</span>;
        }
      },
    }, // New column for Rank
    {
      field: "sender",
      headerName: "Player",
      flex: 1,
      filter: "agSetColumnFilter",
      maxWidth: 500,
      minWidth: 500,
    },
    {
      field: "totalValue",
      headerName: "Total Value",
      flex: 1,
      valueFormatter: (params) => {
        if (!params.data) return ""; // Return an empty string if data is null/undefined
        return formatValue(params.data.totalValue, 18, tokenIdentifier);
      },
      comparator: (valueA, valueB) => {
        // Convert BigInt to Number for both values before comparing
        let numericValueA = Number(valueA);
        let numericValueB = Number(valueB);
        return numericValueA - numericValueB;
      },
      maxWidth: 250,
      minWidth: 250,
    },
    /*{
            field: "earliestTimestamp",
            headerName: "Earliest Transaction Time",
            flex: 1
        },*/
    {
      field: "timeBonus",
      headerName: "Early Entry Bonus",
      maxWidth: 145,
      minWidth: 145,
    },
    {
      field: "totalValueBonus",
      headerName: "Total Value Bonus",
      maxWidth: 145,
      minWidth: 145,
    },
    {
      field: "totalScore",
      headerName: "Total Score",
      maxWidth: 145,
      minWidth: 145,
    },
  ];

  const convertToTimestamp = (date) => {
    // convert the date to a unix timestamp and console log it
    const timestamp = Math.round(new Date(date).getTime() / 1000);
    console.log("timestamp: " + timestamp);
    return timestamp;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_MAINNET === "TRUE"
              ? "https://api.multiversx.com"
              : "https://devnet-api.multiversx.com"
          }/accounts/${walletAddress}/transactions?receiver=${walletAddress}&token=${tokenIdentifier}&status=success&size=${resultsSize}&after=${convertToTimestamp(
            startDate
          )}&before=${convertToTimestamp(endDate)}`
        );
        const data = await response.json();

        // console log the fetch url
        console.log(
          `${
            process.env.REACT_APP_MAINNET === "TRUE"
              ? "https://api.multiversx.com"
              : "https://devnet-api.multiversx.com"
          }/accounts/${walletAddress}/transactions?receiver=${walletAddress}&token=${tokenIdentifier}&status=success&size=${resultsSize}&after=${convertToTimestamp(
            startDate
          )}&before=${convertToTimestamp(endDate)}`
        );

        console.log(data);

        let timeBonus = initialTimeBonus;

        // Aggregate data by sender with sum of values and earliest timestamp
        let aggregatedData = data.reduce((acc, tx) => {
          const value = BigInt(tx.value);
          const timestamp = tx.timestamp;
          // Use senderAssets.name if available, otherwise use sender
          const senderKey =
            tx.senderAssets && tx.senderAssets.name
              ? tx.senderAssets.name
              : tx.sender;
          if (acc[senderKey]) {
            acc[senderKey].totalValue += value;
            if (acc[senderKey].earliestTimestamp > timestamp) {
              acc[senderKey].earliestTimestamp = timestamp;
            }
          } else {
            acc[senderKey] = {
              sender: tx.sender, // Retains the original sender address
              senderAssetsName: tx.senderAssets ? tx.senderAssets.name : "", // Optional: Only add if exists
              totalValue: value,
              earliestTimestamp: timestamp,
            };
          }
          return acc;
        }, {});

        // Sort and map to calculate bonuses
        aggregatedData = Object.values(aggregatedData)
          .sort((a, b) => a.earliestTimestamp - b.earliestTimestamp)
          .map((sender) => {
            const totalValueInEther =
              Number(sender.totalValue) / Math.pow(10, 18); // Convert BigInt to Number for calculation
            const totalValueBonus = Math.floor(
              totalValueInEther * totalValueMultiplier
            ); // Now it's safe to multiply rounded down to nearest 1
            const totalScore = timeBonus + totalValueBonus; // timeBonus is already a Number, so this is safe

            // Update timeBonus for the next sender, ensuring it does not go below 0
            timeBonus = Math.max(0, timeBonus - timeBonusDecrement);

            return {
              ...sender,
              timeBonus: totalScore - totalValueBonus, // Corrected to reflect the actual timeBonus added to totalScore
              totalValueBonus,
              totalScore,
            };
          });

        // After calculating totalScore for each sender...
        aggregatedData = Object.values(aggregatedData)
          .sort((a, b) => a.earliestTimestamp - b.earliestTimestamp)
          .map((sender, index) => {
            // Existing logic to calculate totalScore...
            return {
              ...sender,
              // Adjustments for timeBonus and totalValueBonus...
            };
          });

        // Sort aggregatedData by totalScore in descending order and assign ranks
        aggregatedData = aggregatedData
          .sort((a, b) => b.totalScore - a.totalScore)
          .map((item, index) => ({
            ...item,
            rank: index + 1, // Assign rank based on sorted position
          }));

        setRowData(aggregatedData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, [tokenIdentifier, walletAddress]);

  function formatValue(value, decimals, tokenIdentifier) {
    // Convert the value to a string
    const valueStr = value.toString();
    // Ensure the string has enough digits by padding with zeros if necessary
    const paddedValue = valueStr.padStart(decimals + 1, "0"); // +1 for at least one digit before the decimal
    // Insert the decimal point at the correct position
    const beforeDecimal = paddedValue.slice(0, -decimals) || "0";
    const afterDecimal = paddedValue.slice(-decimals).replace(/0+$/, ""); // Remove trailing zeros

    // Apply comma formatting only to the part before the decimal
    const formattedBeforeDecimal =
      BigInt(beforeDecimal) > 0
        ? beforeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ",") // Add comma as thousand separator only if > 0
        : beforeDecimal;

    // Concatenate the formatted whole number part with the decimal part
    let formattedValue = afterDecimal
      ? `${formattedBeforeDecimal}.${afterDecimal}`
      : formattedBeforeDecimal;

    // Append token identifier
    formattedValue += ` ${tokenIdentifier}`;

    return formattedValue;
  }

  const defaultColDef = {
    sortable: true,
    flex: 1,
  };

  const gridOptions = {
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    rowData: rowData,
    domLayout: "autoHeight", // Optional, for responsive height
    // Define the default sort model
    defaultSortModel: [
      {
        colId: "rank",
        sort: "asc",
      },
    ],
  };

  return (
    <>
      <div className="text-center space-y-5 my-8">
        <h1 class="inline bg-gradient-to-r from-red-500 via-sky-400 to-slate-500 bg-clip-text font-display text-5xl tracking-tight text-transparent">
          Kingpin<span class="text-3xl px-1 m">of</span>
          <span class="font-black">MVX</span>
        </h1>
        <h2 className="mt-2 text-xl font-bold tracking-tight text-white sm:text-xl">
          Start:{" "}
          {startDate
            .toISOString()
            .slice(0, 16)
            .replace("T", " ")
            .replace(":", "h:") + "m | UTC "}
          End:{" "}
          {endDate
            .toISOString()
            .slice(0, 16)
            .replace("T", " ")
            .replace(":", "h:") + "m UTC"}
        </h2>
      </div>
      <div
        className="ag-theme-quartz ag-theme-quartz-dark max-w-7xl mx-auto px-5 md:px-0 pb-24"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          paginationPageSizeSelector={paginationPageSizeSelector}
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
          animateRows={true}
        />
      </div>
    </>
  );
};

export default TableWhitelistLeaderboard;

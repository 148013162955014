import React from 'react'

import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";



const SocialLink = ({text, icon, link}) => {

    // A mapping from icon name to the actual component
    const iconMapping = {
        x: <FaXTwitter  className='h-7' style={{ fontSize: 20 }} />,
        telegram: <FaTelegramPlane  className='h-7' style={{ fontSize: 25 }} />,
        discord: <FaDiscord  className='h-7 bg-rose-500' style={{ fontSize: 25 }} />,
    };


    return (
        <a
            href={link}
            className="inline-block px-3 py-1 text-lg font-semibold leading-6 text-white bg-rose-500 rounded-lg shadow-md hover:bg-rose-600 focus-visible:ring focus-visible:ring-rose-500 focus-visible:ring-opacity-50"
            target="_blank"
            rel="noreferrer"
            title={"Check out our " + text}
        >
            {text} 

            {/* Render the icon if it exists in the mapping */}
            {icon && iconMapping[icon.toLowerCase()] ? iconMapping[icon.toLowerCase()] : null}
        </a>
        
    )
}

export default SocialLink
import React from "react";
import ComicPanel from "./ComicPanel";

// Import the image from its path relative to this file
import bg_cityscape from "../images/cityscape.jpg"
import bg_coktailbar from "../images/coktail-bar.jpg"
import gif_pirate from "../videos/pirate.gif"
import gif_cyborg from "../videos/cyborg.gif"
import gymbro from "../images/gymbro.png"

const Comic = () => {
  return (
    <div  id="comic" className="rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10 mt-16 max-w-7xl mx-auto">
      <div className="flex flex-wrap p-1">
        <ComicPanel
          boxColor="bg-radial-yellow"
          topLeftText="And so it begins......."
          bottomRightText="Calling All VC's"
          speechText=""
          width="basis-full md:basis-1/2"
          imgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/1f852f22-24ae-4d4b-880b-da51715a0c44/variations/Default_A_beaver_Powerful_Venture_capitalist_with_a_top_hat_0_1f852f22-24ae-4d4b-880b-da51715a0c44_0.png"
          backgroundImgUrl={bg_cityscape}
          animateWalking={true}
        />
        <ComicPanel
          boxColor="bg-radial-blue"
          topLeftText=""
          bottomRightText="Ladies"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/a5eb323f-475a-4092-9de5-bf1713271127/variations/Default_A_fierce_and_cunning_beaver_queen_adorned_in_a_sleek_s_2_a5eb323f-475a-4092-9de5-bf1713271127_0.png"
          backgroundImgUrl={bg_coktailbar}
          animateWalking={false}
          animateOnScroll={true}          
        />
        <ComicPanel
          boxColor="bg-radial-black"
          topLeftText=""
          bottomRightText="CryptoBros"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/c2cd5122-b2bc-4c33-a62d-7bb269317865/variations/Default_A_fierce_and_cunning_beaver_wearing_a_black_hoody_0_c2cd5122-b2bc-4c33-a62d-7bb269317865_0.png"
          backgroundImgUrl={false}
          animateWalking={false}
        />
        <ComicPanel
          boxColor="bg-radial-black"
          topLeftText=""
          bottomRightText="Moonboi's"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/f9b62d26-6622-4353-a79d-8d447924a3b9/variations/Default_A_beaver_astronaut_2_f9b62d26-6622-4353-a79d-8d447924a3b9_0.png"
          backgroundImgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/32960a6f-0412-48a6-8421-af3fcb5c5a5e/Default_space_with_distant_stars_and_the_moon_in_the_backgroun_2.jpg"
          animateWalking={false}
          animateOnScroll={true}  
        />
        <ComicPanel
          boxColor="bg-radial-black"
          topLeftText=""
          bottomRightText="Maxis"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/749694b8-4adc-441e-9e7d-afd0010d5959/variations/Default_A_Traditional_Samurai_beaver_leader_3_749694b8-4adc-441e-9e7d-afd0010d5959_0.png"
          backgroundImgUrl="https://cdn.leonardo.ai/users/b8b131aa-6476-4120-90ba-f2fe7997e96c/generations/0cf219c6-7297-4fed-8578-2f6a8ca62126/Default_A_bamboo_forest_background_3.jpg"
          animateWalking={false}
          animateOnScroll={true}
          bgAnimationDirection="vertical"
        />
        <ComicPanel
          boxColor="bg-radial-black"
          topLeftText=""
          bottomRightText="HODLers"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl=""
          videoGifUrl={gif_pirate}
          backgroundImgUrl=""
          animateWalking={false}
          animateOnScroll={false}
        />
         <ComicPanel
          boxColor="bg-radial-blue"
          topLeftText=""
          bottomRightText="GymBros"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl={gymbro}
          videoGifUrl={false}
          backgroundImgUrl=""
          animateWalking={false}
          animateOnScroll={false}
        />
        <ComicPanel
          boxColor="bg-radial-blue"
          topLeftText=""
          bottomRightText="And Bots"
          speechText=""
          width="basis-full md:basis-1/4"
          imgUrl={false}
          videoGifUrl={gif_cyborg}
          backgroundImgUrl=""
          animateWalking={false}
          animateOnScroll={false}
        />
      </div>
    </div>
  );
};

export default Comic;

import React from 'react'
import { ShieldCheckIcon, UserGroupIcon, CurrencyDollarIcon } from '@heroicons/react/20/solid';



const Overview = () => {
    return (
        <div id="overview" className="relative isolate overflow-hidden py-24 sm:py-20">
            <div
                className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-36 xl:ml-56"
                aria-hidden="true"
            >
                <div
                    className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                    style={{
                        clipPath:
                            'polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)',
                    }}
                />
            </div>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0">
                    <p className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">Elevate Your Strategy</p>
                    <h1 className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Empower Your Empire</h1>
                    <p className="mt-6 text-xl leading-8 text-gray-200">
                        Our token not only serves as a catalyst for transactions within the MultiversX universe but also
                        as a cornerstone for strategic growth and community engagement. It’s more than a currency; it’s a
                        badge of honor, representing your prowess in strategy and influence. Join our exclusive token launch
                        to secure your foundation in a world of honor and corruption.
                    </p>
                </div>
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
                    <div className="relative lg:order-last lg:col-span-5">
                        <svg
                            className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
                            aria-hidden="true"
                        >
                            <defs>
                                <pattern
                                    id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                                    width={200}
                                    height={200}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M0.5 0V200M200 0.5L0 0.499983" />
                                </pattern>
                            </defs>
                            <rect width="100%" height="100%" strokeWidth={0} fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)" />
                        </svg>
                        <figure className="border-l border-indigo-600 pl-8">
                            <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-200">
                                <p>
                                    “In the shadows of the MultiversX, a new era of strategy and power unfolds. Here, tokens are more than currency; they are the key to ascension. Once 2,500 unique visionaries hold our token, a gateway opens, bestowing upon them NFTs that are not merely art, but a passage into the realm of influence. These NFTs mark the genesis of a game where at first the largest holder is crowned Kingpin, (everyone else is ranked by token size.) a title of immense power and privilege and rewards, but beware—the crown is a heavy burden, bestowed only for a week. Below the throne, the realm is restless; every position, except for the Kingpin, is in perpetual motion, reshuffled every 30 minutes based on cryptic metrics known only to the game's architects. In this world where loyalty and honor may be your greatest assets or your most perilous deceits, aligning with the right factions is not just strategy—it's survival.”
                                </p>
                            </blockquote>
                        </figure>



                    </div>
                    <div className="max-w-xl text-base leading-7 text-gray-100 lg:col-span-7">
                        <h2 className="text-xl font-bold text-gray-200">Token Launch Details</h2>
                        <ul className="mt-4 space-y-4">
                            <li>
                                <strong>Subscription Period:</strong> 24 hours only. Act swiftly to partake in the strategic genesis of our universe.
                            </li>
                            <li>
                                <strong>Participation Method:</strong> Transfer tokens to join. Post-launch, our system calculates the total raised and adjusts distributions based on strategic game mechanics.
                            </li>
                            <li>
                                <strong>Token Pricing and Distribution:</strong> Set to dynamically influence in-game power balances and alliances, fostering a deeply engaging strategic environment.
                            </li>
                        </ul>
                        <p className="mt-8">
                            A deflationary total supply of 1 billion tokens powers the economic and strategic exchanges within our universe. Initial participants can significantly impact the game's development, with rewards structured to amplify your influence and legacy.
                        </p>
                        <h2 className="mt-12 text-xl font-bold text-gray-200">Strategic Impact and Rewards</h2>
                        <ul className="mt-4 space-y-4">
                            <li>
                                Initial backers are pivotal, setting the stage for future alliances and conflicts. Your early decisions craft the tapestry of intrigue and power struggles to come.
                            </li>
                            <li>
                                Exclusive bonuses and advantages await those who adeptly navigate the initial phase, offering a unique blend of strategic depth and competitive edge.
                            </li>
                        </ul>
                        <p className="mt-8">
                            As the universe evolves, so too will the ways in which you can wield your influence. Prepare to embark on a journey where strategy, not just strength, dictates your path to supremacy.
                        </p>


                        <ul className="mt-8 space-y-8 text-gray-100">
                            <li className="flex gap-x-3">
                                <CurrencyDollarIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-200">Empower Your Transactions.</strong>
                                    Each trade strengthens your position and the community, driving forward the vibrancy of the MultiversX universe.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <UserGroupIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-200">Build Alliances.</strong>
                                    Forge strategic partnerships to amplify your influence and ascend through the ranks of power.
                                </span>
                            </li>
                            <li className="flex gap-x-3">
                                <ShieldCheckIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                                    <strong className="font-semibold text-gray-200">Champion Honor and Strategy.</strong>
                                    Navigate the delicate balance between honor and corruption to outsmart your competitors and emerge victorious.
                                </span>
                            </li>
                        </ul>
                        <p className="mt-8">
                            Join our ranks to increase the chain's vibrancy and earn your place among the elite with every transaction, strategy,
                            and endorsement. The path to glory is fraught with decisions that will test your mettle—will you rise as a beacon of honor,
                            or will you weave a web of corruption to claim power? The choice is yours.
                        </p>
                        <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-200">more to follow...</h2>
                        <p className="mt-6">
                            keep an eye out on our socials
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Overview
import React from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";

gsap.registerPlugin(ScrollTrigger);

const ComicPanel = ({
  boxColor,
  topLeftText,
  bottomRightText,
  speechText,
  width,
  imgUrl,
  videoGifUrl, // New video URL prop
  backgroundImgUrl,
  animateWalking = false, // New prop to control animation
  animateOnScroll = false, // New prop to control animation
  bgAnimationDirection = 'horizontal', // New prop for background animation direction

}) => {

  const videoRef = useRef(null);
  const imageContainerRef = useRef(null); // Ref for the container of the image to animate
  const backgroundRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
        videoRef.current.muted = true;
    }
}, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (animateWalking && imageContainerRef.current) {
      // Apply the walking animation if animateWalking is true
      gsap.fromTo(imageContainerRef.current,
        { y: 0 },
        {
          y: +5, // Adjust the y-value for desired vertical movement
          repeat: -1, // Infinite repeat
          yoyo: true, // Move up and then back down
          duration: 1.25, // Duration for one complete cycle (up and down)
          ease: "sine.inOut", // Smooth easing for a more natural effect
        }
      );
    }
  }, [animateWalking]); // Dependency array includes animateWalking to re-run if it changes


  useEffect(() => {
    if (animateOnScroll && backgroundRef.current) {
      // Set initial scale of the image for a zoomed-in effect.
      gsap.set(backgroundRef.current.firstChild, { scale: 1.75 });

      // Determine animation properties based on the bgAnimationDirection prop.
      const animationProps = bgAnimationDirection === 'horizontal'
        ? { x: 30 } // Moves background 100px to the right for horizontal.
        : { y: -50 }; // Moves background 100px up for vertical (use positive value to move down).

      // Apply the animation with GSAP.
      gsap.to(backgroundRef.current.firstChild, {
        scrollTrigger: {
          trigger: backgroundRef.current,
          start: "top bottom", // Start when the top of the trigger hits the bottom of the viewport
          end: "bottom top", // End when the bottom of the trigger hits the top of the viewport
          scrub: 1, // Smooth animation that links the scroll amount to the animation progress
        },
        ...animationProps, // Spread the calculated animation properties
        ease: "power1.inOut", // Use an easing function for a more natural transition
      });
    }
  }, [animateOnScroll, bgAnimationDirection]); // React to changes in these props

  return (
    <div className={`bg-white border-2 border-black inline-block flex-1 h-52 overflow-hidden relative ${boxColor} ${width} m-[1vmin] shadow-[0_6px_6px_-6px_#000000]`}>
      {/* Add a new div to hold the background image */}
      {backgroundImgUrl && (
        <div ref={backgroundRef} className="absolute inset-0 z-0">
          <img src={backgroundImgUrl} alt="" className="w-full h-full object-cover" />
        </div>
      )}

      {/* Use an <img> tag for the GIF */}
      {videoGifUrl ? (
        <div ref={imageContainerRef} className="absolute bottom-0 inset-0 flex justify-center items-center">
          <img src={videoGifUrl} alt="" className="w-full object-cover" />
        </div>
      ) : imgUrl && (
        <div ref={imageContainerRef} className="absolute bottom-0 inset-0 flex justify-center items-center">
          <img src={imgUrl} alt="" className="h-full object-cover" />
        </div>
      )}
      

      {/* Add the text elements */}
      {topLeftText && (
        <p className="absolute top-[-2px] left-[-6px] transform skew-x-[-15deg] bg-white border-2 border-black m-0 px-2.5 py-[3px]">
          {topLeftText}
        </p>
      )}
      {bottomRightText && (
        <p className="absolute bottom-[-2px] right-[-6px] transform skew-x-[-15deg]  bg-white border-2 border-black m-0 px-2.5 py-[3px]">
          {bottomRightText}
        </p>
      )}
      {speechText && (
        <div className="absolute top-0 speech m-[.5em] p-[.5em_1em] bg-white border-2 border-black rounded-[12px] inline-block ml-10">
          {speechText}
          <div className="speech-before"></div> {/* Tailwind CSS doesn't directly support pseudo-elements */}
        </div>
      )}


    </div>
  );
}

export default ComicPanel;

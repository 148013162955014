import { DocumentDuplicateIcon, } from "@heroicons/react/20/solid";
import { CopyToClipboard } from "react-copy-to-clipboard";
import React from "react";
import { useEffect, useState } from "react";
import SocialLink from "./SocialLink";
import { Link } from '@mui/material';
import { FaInfoCircle } from "react-icons/fa";



const Hero = ({ walletAddress }) => {

  const [copied, setCopied] = React.useState(false);
  const [raisedEGLD, setRaisedEGLD] = React.useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  // this useEffect will run only once when the component mounts
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // this useEffect will run every time the value of `copied` changes
  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [copied]);

  // this useEffect will run every time the value of `walletAddress` changes
  useEffect(() => {
    // Function to fetch the balance from the API
    const fetchBalance = async () => {
      try {
        const response = await fetch(`https://api.elrond.com/accounts/${walletAddress}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // Assuming the balance is returned in the smallest unit, convert it to EGLD
        const balanceEGLD = data.balance / 10 ** 18;
        setRaisedEGLD(balanceEGLD.toFixed(2)); // Keeping two decimal places for simplicity
      } catch (error) {
        console.error("Failed to fetch account balance:", error);
      }
    };

    fetchBalance();
  }, [walletAddress]); // Re-run this effect if walletAddress changes

  const displayAddress = isMobile
    ? `${walletAddress.slice(0, 15)}...${walletAddress.slice(-15)}`
    : walletAddress;


  return (
    <div className="py-24 sm:py-32 lg:pb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="inline bg-gradient-to-r from-red-500 via-sky-400 to-slate-500 bg-clip-text font-display text-5xl tracking-tight text-transparent">Kingpin<span className='text-3xl px-1 m'>of</span><span className='font-black'>MVX</span></h1>

          <div className="mt-6 space-x-4">
            <SocialLink text="" link="https://twitter.com/KingpinOfMVX" icon="X" />
            <SocialLink text="" link="https://t.me/KingpinOfMVX" icon="Telegram" />
            <SocialLink text="" link="#" icon="Discord" />
            <Link href="#overview" className="inline-block px-3 py-1 text-lg font-semibold leading-6 text-white bg-rose-500 rounded-lg shadow-md hover:bg-rose-600 focus-visible:ring focus-visible:ring-rose-500 focus-visible:ring-opacity-50" >
              <FaInfoCircle  style={{ fontSize: 25 }} className="text-white h-7"/>
            </Link>
          </div>

          <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl mt-10 drop-shadow-md">
            Dominate the chain
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Rise to the top of the chain in this strategic battleground. Pledge your loyalty and lead with honor, or weave a web of deceit to manipulate the market and your rivals.
          </p>

          <div className="mt-16 sm:mt-20 lg:mt-16">
            <p className="text-lg leading-8 text-gray-300 mb-2">Initial Funds walletAddress:</p>
            <div className="inline-flex space-x-6">

              <CopyToClipboard
                text={walletAddress}
                //text={walletAddress}
                onCopy={() => setCopied(true)}
              >
                <button className="inline-flex items-center space-x-2 rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20 hover:bg-indigo-500/20 focus:outline-none focus:ring-indigo-500/50">
                  <span>{copied ? "Address Copied!" : displayAddress}</span>
                  {!copied && <DocumentDuplicateIcon className="w-5 h-5" />}
                </button>
              </CopyToClipboard>
            </div>
          </div>

          {/* Flex container to center the raised amount */}
          <div className="mt-10 flex justify-center items-center">
            <div className="rounded-xl bg-indigo-500 px-5 py-2.5 text-xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">
              Raised: <span className="text-3xl">{raisedEGLD}</span> EGLD
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Hero;